import React from "react";
import { StaticQuery, graphql } from "gatsby";
import logo1 from "../../assets/images/index/xero-logo.png";
import logo2 from "../../assets/images/index/experian-logo.png";
import logo3 from "../../assets/images/index/itela_logo.png";
import logo4 from "../../assets/images/index/lilardia-logo.png";
import ReactTooltip from "react-tooltip";

export default function Alliances() {
  return (
    <StaticQuery
      query={graphql`
        query {
          logo_1: file(relativePath: { eq: "index/xero-logo.png" }) {
            childImageSharp {
              fluid(maxWidth: 378) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          logo_2: file(relativePath: { eq: "index/experian-logo.png" }) {
            childImageSharp {
              fluid(maxWidth: 378) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          logo_3: file(relativePath: { eq: "index/itela_logo.png" }) {
            childImageSharp {
              fluid(maxWidth: 378) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          logo_4: file(relativePath: { eq: "index/lilardia-logo.png" }) {
            childImageSharp {
              fluid(maxWidth: 105) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={(data) => (
        <section className="alliances-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="section-title">
                  <h2>Our Alliances</h2>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <a
                      className="alliance-card"
                      href="https://www.xero.com/"
                      target="_blank"
                      data-tip
                      data-for="xero"
                    >
                      <div className="img">
                        <img src={logo1} />
                        {/* <Img className="img-fluid" fluid={data.logo_1.childImageSharp.fluid} /> */}
                      </div>
                    </a>
                    <ReactTooltip id="xero" place="top" effect="solid">
                      Xero Limited
                    </ReactTooltip>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <a
                      className="alliance-card"
                      href="https://www.experian.com/"
                      target="_blank"
                      data-tip
                      data-for="experian"
                    >
                      <div className="img">
                        <img src={logo2} />
                        {/* <Img fluid={data.logo_2.childImageSharp.fluid} /> */}
                      </div>
                    </a>
                    <ReactTooltip id="experian" place="top" effect="solid">
                      Experian PLC
                    </ReactTooltip>
                  </div>
                </div>
              </div>

              {/* tech partners  */}
              <div className="col-lg-6 col-12">
                <div className="section-title">
                  <h2>Our Technology Partners</h2>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <a
                      className="alliance-card"
                      href="https://www.itelasoft.com/"
                      target="_blank"
                      data-tip
                      data-for="its"
                    >
                      <div className="img">
                        <img src={logo3} />
                        {/* <Img
                          fluid={data.logo_3.childImageSharp.fluid}
                          imgStyle={{ objectPosition: "center" }}
                        /> */}
                      </div>
                    </a>
                    <ReactTooltip id="its" place="top" effect="solid">
                      iTelasoft (Pvt) Ltd
                    </ReactTooltip>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <a
                      className="alliance-card"
                      href="https://www.lilardiacapital.com/"
                      target="_blank"
                      data-tip
                      data-for="lilardia"
                    >
                      <div className="img">
                        <img src={logo4} />
                        {/* <Img
                          fluid={data.logo_4.childImageSharp.fluid}
                          style={{ maxHeight: "70px" }}
                          imgStyle={{ objectFit: "contain" }}
                        /> */}
                      </div>
                    </a>
                    <ReactTooltip id="lilardia" place="top" effect="solid">
                      Lilardia Capital
                    </ReactTooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    />
  );
}
